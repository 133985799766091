footer {
  position: relative;
  background: -webkit-gradient(linear, left bottom, left top, from(#262626), to(#272240));
  background: linear-gradient(to top, #262626, #272240);
}

.ubicacion {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  height: auto;
  padding: 15px;
  margin: 0 auto;
}
.ubicacion .ubicacion_footer-left {
  padding: 20px;
  color: var(--celeste-oscuro);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: clamp(1.4rem, 5vw, 1.8rem);
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.ubicacion .ubicacion_footer-left .atencion_oficina-container {
  background-color: rgba(102, 176, 203, 0.4588235294);
  border-radius: 10px;
  margin: 25px 0;
}
.ubicacion .ubicacion_footer-left .atencion_oficina-container p {
  font-size: clamp(1.1rem, 4vw, 1.3rem);
  padding: 10px;
  margin: 0;
}
.ubicacion .ubicacion_footer-left a {
  text-decoration: none;
  color: var(--celeste-claro);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.ubicacion .ubicacion_footer-left span {
  font-size: 3rem;
  font-weight: bold;
  color: var(--celeste-medio);
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin-top: 15px;
}
.ubicacion .ubicacion_footer-left span:hover {
  color: green;
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
}
.ubicacion .ubicacion_footer-right {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.ubicacion .ubicacion_footer-right p {
  font-size: clamp(1rem, 4vw, 1.2rem);
  color: white;
  opacity: 0.7;
  text-align: center;
  margin-top: 15px;
  padding: 15px;
}
.ubicacion .ubicacion_footer-right iframe {
  width: 100%;
  height: 60%;
  -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
  border: none;
  border-radius: 5px;
}
.ubicacion .ubicacion_footer-right button {
  margin: 30px;
  padding: 10px;
  width: 170px;
  height: 40px;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  background-color: black;
}
.ubicacion .ubicacion_footer-right h5 {
  color: white;
  text-align: center;
  font-size: clamp(1.6rem, 5vw, 2rem);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ubicacion .ubicacion_footer-right svg {
  width: 50px;
  fill: var(--celeste-medio);
  margin-top: 20px;
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
}
.ubicacion .ubicacion_footer-right svg:hover {
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
  fill: green;
}
.ubicacion .ubicacion_footer-right span {
  font-size: 60px;
  color: var(--celeste-medio);
}
.ubicacion .ubicacion_footer-right span:hover {
  -webkit-transition: 300ms ease;
  transition: 300ms ease;
  color: var(--celeste-oscuro);
}
.ubicacion .ubicacion_footer-right .wha-ema {
  gap: 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  opacity: 0.7;
}
.ubicacion .ubicacion_footer-right .wha-ema .experiencia {
  color: #ffc100;
  font-size: 3rem;
}

.conclucion {
  background: -webkit-gradient(linear, left bottom, left top, from(#262626), to(rgba(39, 34, 64, 0)));
  background: linear-gradient(to top, #262626, rgba(39, 34, 64, 0));
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  margin-top: 50px;
  place-items: center;
}
.conclucion .footer_left {
  padding: 20px;
  color: whitesmoke;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 10px;
  font-size: 15;
  height: 100%;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.conclucion .footer_left .computecnica {
  width: 300px;
  height: 100px;
}
.conclucion .footer_left :nth-child(2) {
  margin-top: 30px;
}
.conclucion .footer_left p {
  font-size: clamp(15px, 3vw, 1rem);
}
.conclucion .footer_left p a {
  text-decoration: none;
  color: var(--celeste-claro);
}
.conclucion .footer_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 90%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: whitesmoke;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.conclucion .footer_right h4 {
  text-align: center;
}
.conclucion .footer_right .social_container {
  /*     margin: 15px 0 20px 0; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  min-width: 200px;
}
.conclucion .footer_right .social_container svg {
  width: 35px;
  height: 35px;
  fill: var(--celeste-claro);
}
.conclucion .footer_right .social_container span {
  font-size: 38px;
  color: var(--celeste-claro);
}
.conclucion .footer_right .pago {
  width: 110px;
  border-radius: 5px;
  /*       margin: 20px auto 20px auto */
}
.conclucion .footer_right .compraqui {
  width: 150px;
}

/* ----------------------------------------------carrusel */
.slide {
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

.carousel-caption {
  color: var(--celeste-claro) !important;
  font-size: 1rem;
  -webkit-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
  padding: 0 0 1 5px 0 !important;
}
.carousel-caption h3 {
  font-size: clamp(1rem, 5vw, 2rem) !important;
  margin: 0 !important;
}
.carousel-caption p {
  font-size: clamp(1rem, 5vw, 1.5rem) !important;
  margin: 0 !important;
}

.carousel-item:hover .carousel-caption {
  background-color: rgba(0, 0, 0, 0.578);
  border-radius: 20px;
  -webkit-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
}
.carousel-item:hover .carousel-caption span {
  font-size: 4rem;
}

.joda {
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  -webkit-filter: brightness(0.5);
          filter: brightness(0.5);
  height: 100% !important;
  -webkit-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
}

.carousel-item:hover .joda {
  -webkit-filter: brightness(1);
          filter: brightness(1);
  -webkit-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
}

.carousel-item {
  height: 100% !important;
  -webkit-transition: all 0.3s linear !important;
  transition: all 0.3s linear !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 20px !important;
  height: 20px;
}

.callFixed {
  z-index: 100;
  background-color: transparent;
  color: white;
  border: 1px solid;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50px;
  font-size: 35px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding-top: 2px;
  padding-right: 4px;
}

.callFixed:hover {
  background-color: #5fd03d;
  color: white;
}

.ws {
  background-color: #5fd03d;
  bottom: 80px;
  padding-top: 0;
  padding-right: 0;
}
.ws:hover {
  background-color: #5fd03d;
  color: white;
}

@media (max-width: 420px) {
  footer .ubicacion {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 0.6fr 1fr !important;
    grid-template-rows: 0.6fr 1fr !important;
    height: auto;
  }
  footer .ubicacion > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  footer .ubicacion > *:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
  footer .ubicacion .ubicacion_footer-left, footer .ubicacion .ubicacion_footer-right {
    padding: 0 !important;
  }
}
@media (max-width: 820px) {
  footer .ubicacion {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: auto;
  }
  footer .ubicacion > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  footer .ubicacion > *:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
  /*   footer .ubicacion_footer-right{
      height: 90vh!important;
    } */
}
@media (min-width: 675px) {
  footer {
    -webkit-filter: blur(0) !important;
            filter: blur(0) !important;
  }
}
@media (max-width: 675px) {
  .windows_container .windows_container--info {
    height: 80vh;
  }
  footer .conclucion {
    height: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 1fr 20px 1fr;
    grid-template-rows: 1fr 1fr;
    place-items: center;
    gap: 20px;
  }
  footer .conclucion > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  footer .conclucion > *:nth-child(2) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
  footer .conclucion .footer_left {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 5px 5px 5px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.179);
  }
  footer .conclucion .footer_left .footer_phone {
    margin-bottom: 20px;
  }
  footer .conclucion .footer_right {
    height: 100%;
  }
  .experiencia {
    font-size: 2.3rem !important;
  }
}