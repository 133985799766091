@tailwind base;
@tailwind components;
@tailwind utilities;
:root{
  /* colores base computecnica */
--celeste-medio: #66B0CB;
--celeste-claro: #88D6D8;
--celeste-oscuro: #433B6A;
/* blancos degradados */
--blanco-claro: #FFFFFF;
--blanco-degradado: #F5F5F7;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  scroll-behavior: smooth;
  
  
  
}
body{

    margin: 0 auto;
    box-shadow: 0px 0px 3px 0px #0a0808, inset 0px 0px 1px 1px #060505;

}
.servicios{
  background:  linear-gradient(to bottom, #000000, #272240);
  img{border-radius: 4px;}
  .p{
    font-size: clamp(1.2rem, 5vw, 1.5rem);
    opacity: .8;

  }
}
::-webkit-scrollbar-thumb {
  background: var(--celeste-oscuro);
  border-radius: 4px;

}
::-webkit-scrollbar-thumb:hover{
  background: var(--celeste-claro);

}
::-webkit-scrollbar {
  width: 7px; 
  height: 1px;   
  background:rgba(5, 2, 0, 0.4);
  border-radius: 4px;
  
}
::-webkit-scrollbar:hover {
  background: #403d3d;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  
}
::-webkit-scrollbar:hover:active {
  background: #403d3d;
}
