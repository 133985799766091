main {
  overflow: hidden;
  height: auto;
  background-color: black;
  -webkit-box-shadow: 2px 1px 7px #e2e1e1, 1px 1px 2px #d8d8d8;
          box-shadow: 2px 1px 7px #e2e1e1, 1px 1px 2px #d8d8d8;
  margin: 0 auto;
}

.intro_container {
  width: 100%;
  height: 110vh;
  background-image: url("C:/Users/NITRO5/Desktop/Computecnica/computecnica/src/assets/macPortada.jpg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: self-end;
      -ms-flex-align: self-end;
          align-items: self-end;
}

.title_container {
  /*   background-color: var(--blanco-degradado); */
  color: whitesmoke;
  text-align: center;
  padding: 0 10px 20px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}
.title_container h1 {
  font-size: clamp(1.7rem, 5vw, 2.5rem);
}
.title_container h2 {
  font-size: clamp(1.2rem, 5vw, 1.5rem);
  opacity: 0.8;
  letter-spacing: 2px;
  font-weight: 300;
}

@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }
  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
@media (min-width: 675px) {
  main {
    -webkit-filter: blur(0) !important;
            filter: blur(0) !important;
  }
}
@media (max-width: 675px) {
  .intro_container {
    height: 100svh;
  }
}
.blurOn {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}