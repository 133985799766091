.nav-icon-8 {
  width: 35px;
  height: 30px;
  margin: 10px 10px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.nav-icon-8 span {
  background-color: var(--celeste-claro);
  position: absolute;
  border-radius: 2px;
  -webkit-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 4px;
}

.nav-icon-8 span:nth-child(1) {
  top: 0px;
  left: 0px;
}

.nav-icon-8 span:nth-child(2) {
  top: 13px;
  left: 0px;
}

.nav-icon-8 span:nth-child(3) {
  bottom: 0px;
  left: 0px;
}

.nav-icon-8:not(.open):hover span:nth-child(1) {
  -webkit-transform: scaleX(0.8);
          transform: scaleX(0.8);
}

.nav-icon-8:not(.open):hover span:nth-child(2) {
  -webkit-transform: scaleX(0.5);
          transform: scaleX(0.5);
}

.nav-icon-8:not(.open):hover span:nth-child(3) {
  -webkit-transform: scaleX(0.8);
          transform: scaleX(0.8);
}

.nav-icon-8.open span:nth-child(1) {
  top: 13px;
}

.nav-icon-8.open span:nth-child(2) {
  top: 13px;
}

.nav-icon-8.open span:nth-child(3) {
  top: 13px;
}