@charset "UTF-8";
.inactive {
  right: -1000px;
}

.active {
  right: 0;
}

/* colores base computecnica */
/* blancos degradados */
/* tanaños de fuentes */
header {
  z-index: 2;
  position: fixed;
  padding: 0 20px;
  background-color: rgb(39, 34, 64);
  /* background-color: #4e8da4d6; */
  height: 60px;
  display: -ms-grid;
  display: grid;
  width: 100%;
  -ms-grid-columns: 0.2fr 1fr;
  grid-template-columns: 0.2fr 1fr;
  -ms-grid-rows: 100%;
  grid-template-rows: 100%;
  place-content: center;
  color: #F5F5F7;
  opacity: 0.95;
}
header > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
header > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
header .header__logo--container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
header .header__logo--container svg {
  height: 60px;
  width: 150px;
}
header nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  list-style: none;
  height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
header nav ul li a {
  text-decoration: none;
  color: #F5F5F7;
  -webkit-transition: ease 300ms;
  transition: ease 300ms;
  /*         font-size: clamp(1rem, 5vw, 1.5rem); */
}
header nav ul li a:hover {
  color: var(--celeste-medio);
  -webkit-transition: ease 300ms;
  transition: ease 300ms;
}
header .icon.nav-icon-8 {
  display: none;
}

@media (max-width: 821px) {
  body header .header__logo--container svg {
    height: 60px;
    width: 150px;
  }
  body header .header__content ul {
    -webkit-box-pack: right;
        -ms-flex-pack: right;
            justify-content: right;
    gap: 7px;
    font-size: 14px;
  }
}
@media (max-width: 675px) {
  body header {
    -ms-grid-columns: 2fr 0.3fr;
    grid-template-columns: 2fr 0.3fr;
  }
  body header .header__logo--container {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
  body header .header__logo--container svg {
    height: 70px;
    width: 150px;
  }
  body header .header__content {
    -webkit-transition: ease 0.5s;
    transition: ease 0.5s;
    width: 85%;
    position: fixed;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: rgba(25, 22, 43, 0.95);
    height: 100vh;
  }
  body header .header__content ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 30px;
    gap: 20px;
  }
  body header .header__content ul li {
    text-align: center;
  }
  body header .header__content ul li a {
    color: var(--blanco_claro);
  }
  .icon.nav-icon-8 {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    display: block !important;
    margin: 0;
  }
}